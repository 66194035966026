<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会！</div>
      <p class="time">2021.01.08</p>

      <p class="space_content" style="text-indent:0">
        近日，厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会。会议由历思党支部书记林巧尔主持，历思全体党员以及稽查局办检党支部全体党员参加本次交流会。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/37-1.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        交流会上，全体党员共同深入学习领会党的十九届五中全会精神，并就相关主题进行交流研讨。全体党员讨论后，要以五中全会精神为指引，探索建立税收和司法鉴定相结合新形式，为厦门经济高质量发展贡献重要的力量。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/37-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/37-3.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/37-4.png')">
      </div>
      <p class="space_content" style="text-indent:0">
        会上还特别邀请了著名笔迹心理学研究与运用专家、文书司法鉴定人金一贵老师，围绕“从书写了解人的个性及心理-字如其人、字变人变”主题，为全体党员进行了一场生动精彩的讲座，让大家感受笔迹心理学的奇妙力量。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/37-5.png')">
      </div>
      <p class="space_content" style="text-indent:0">
        经过深入的研讨交流，双方都一致认为，作为党员更应该发挥好先锋模范作用，认真学习贯彻五中全会精神，结合实际工作，将党建工作与企业业务进行深度融合。希望在今后的工作中，双方能够继续加强沟通交流，进一步拓展党建工作新思路，谋划党建工作新发展，不断推进双方党建工作迈上新台阶。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/36'"><p class="ellipsis1">浙江省禁毒总队、福建省禁毒总队领导一行莅临历思就“污水验毒”等技术进行考察交流！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/38'"><p class="ellipsis1">堃晟入选福建省污染地块调查评估、治理修复单位专业机构推荐名录</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
